<template>
  <div class="cover">
    <nav class="wl">
      <img src="@/assets/logo_mini.svg" alt="logo" />
      <h1>ASSETANCE</h1>
    </nav>
    <router-view />
    <footer class="wl">
      <img src="@/assets/logo_mini_light.svg" alt="logo" />
      <h1>ASSETANCE</h1>
      <sub>Copyright © 2023. Assetance All rights reserved.</sub>
      <div class="social">
        <a href="https://www.instagram.com/assetance/">
          <img src="@/assets/social_inst.png" alt="instagram page link" />
        </a>
        <!-- <a href="#">
          <img src="@/assets/social_tiktok.png" alt="tiktok page link" />
        </a>
        <a href="#">
          <img src="@/assets/social_linkedin.png" alt="LinkedIn page link" />
        </a> -->
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.cover {
  height: 100%;
  padding: 1rem;
  background-image: url(@/assets/wave.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  @media screen and (max-width: 1070px) {
    background-size: 500%;
  }
}

nav.wl {
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-radius: 1.25rem;
  box-shadow: 0px 20px 22px -20px rgba(71, 182, 254, 0.2);

  img {
    width: 32px;
    margin-right: 1rem;
  }

  h1 {
    margin: 0;
    font-size: 1.75rem;
    background-color: var(--primary);
    background: -webkit-linear-gradient(35deg, var(--primary), var(--accent));
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

footer.wl {
  margin-top: 5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: var(--text-marker);
  box-shadow: 0px 14px 15px 0px rgba(11, 33, 46, 0.1);
  @media screen and (max-width: 1070px) {
    flex-flow: column nowrap;
  }
  img {
    width: 32px;
    margin-right: 1rem;
    @media screen and (max-width: 1070px) {
      margin: 1rem 0rem;
    }
  }

  h1 {
    margin: 0;
    font-size: 1.75rem;
    background-color: var(--primary);
    background: -webkit-linear-gradient(35deg, var(--accent), var(--secondary));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 1070px) {
      margin-bottom: 1rem;
    }
  }

  sub {
    margin: 0 auto;
    padding-top: 0.5rem;
    color: var(--background-marker);
    opacity: 0.5;
    @media screen and (max-width: 1070px) {
      order: 3;
      margin: 1rem 0rem;
    }
  }
  .social {
    width: auto;
    @media screen and (max-width: 1070px) {
      order: 2;
      margin: 2rem 0rem;
    }
    a {
      margin: 1rem 1rem;
      padding: 1rem;
      transition-duration: 0.3s;

      &:hover {
        cursor: pointer;
        transition-duration: 0.3s;
      }

      & > img {
        margin: 0px;
      }
    }
  }
}
</style>
