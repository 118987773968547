<template>
  <div class="home wl">
    <main>
      <section role="banner" class="hero">
        <div class="bannerText">
          <p class="comingSoon">__Coming Soon!</p>
          <p class="text">
            Discover the Power of Fractional Real Estate Investing with
            <span class="assetance">ASSETANCE</span>
          </p>
        </div>
        <img
          src="@/assets/under_construction.png"
          alt="site under construction"
        />
      </section>
      <section class="signup">
        <h2>be the first to know when we launch!</h2>
        <p class="error" :class="{ errorClass: errorOn }">{{ errorData }}</p>
        <form @submit.prevent="onSubmit()" method="post">
          <label for="emailInput">email</label>
          <input
            v-model.trim="userEmail"
            id="emailInput"
            name="email"
            required=""
            placeholder="Email"
          />
          <button type="submit" id="submitbutton">
            <span>Sign Up</span>
            <div class="loaderContainer">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </button>
        </form>
        <sub>
          we hate spam too, we will only notify you when we’re launching
        </sub>
      </section>
    </main>
  </div>
</template>

<script setup>
// @ is an alias to /src
import axios from 'axios';
import { ref } from 'vue';

axios.defaults.withCredentials = true;

const userEmail = ref('');
const errorData = ref(null);
const errorOn = ref(false);

async function onSubmit() {
  // change submit button styling
  let loaderElement = document.querySelector('.lds-ellipsis');
  let spanText = document.querySelector('button span');
  let submitButton = document.querySelector('button#submitbutton');
  let emailInput = document.querySelector('input#emailInput');
  let rect = submitButton.getBoundingClientRect();
  submitButton.style.minWidth = rect.width + 'px';
  submitButton.style.minHeight = rect.height + 'px';
  loaderElement.style.display = 'inherit';
  spanText.style.display = 'none';
  submitButton.disabled = true;
  // validating ::
  if (validateEmail()) {
    axios
      .post('https://coming-soon-api.assetance.com/v1/add', {
        email: userEmail.value,
      })
      .then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          emailInput.classList.toggle('done');
          loaderElement.style.display = 'none';
          spanText.style.display = 'inherit';
          submitButton.style.minWidth = 'inherit';
          submitButton.style.minHeight = 'inherit';
          submitButton.innerHTML = "you're in";
        },
        (error) => {
          console.log(error.response.data.message);
          if (
            error.response.data.message == 'The email has already been taken.'
          ) {
            emailInput.classList.toggle('done');
            loaderElement.style.display = 'none';
            spanText.style.display = 'inherit';
            submitButton.style.minWidth = 'inherit';
            submitButton.style.minHeight = 'inherit';
            submitButton.innerHTML = 'success';
          } else {
            errorOn.value = true;
            errorData.value = error.response.data.message;
            loaderElement.style.display = 'none';
            spanText.style.display = 'inherit';
            submitButton.style.minWidth = 'inherit';
            submitButton.style.minHeight = 'inherit';
            submitButton.disabled = false;
          }
        }
      );
  } else {
    // console.log('input error');
    loaderElement.style.display = 'none';
    spanText.style.display = 'inherit';
    submitButton.style.minWidth = 'inherit';
    submitButton.style.minHeight = 'inherit';
    submitButton.disabled = false;
  }
}

function validateEmail() {
  if (
    !userEmail.value.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errorData.value = 'Please enter a valid email address';
    errorOn.value = true;
    return false;
  } else {
    errorOn.value = false;
    return true;
  }
}
</script>

<style lang="scss">
main {
  display: flex;
  flex-flow: column nowrap;

  section.hero {
    margin-top: 2rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @media screen and (max-width: 1070px) {
      margin-top: 2rem;
      flex-flow: column nowrap;
    }
    .bannerText {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      max-width: 40rem;
      p.comingSoon {
        margin-bottom: 1rem;
        padding: 1rem;
        font-weight: bold;
        font-size: 1.5rem;
        background: -webkit-linear-gradient(
          35deg,
          var(--primary),
          var(--accent)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        border: 1px solid var(--secondary);
        border-radius: 0.9375rem;
        box-shadow: 1px 5px 9px 0px rgba(0, 86, 224, 0.1);
      }
      p.text {
        text-align: left;
        font-family: Lora;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.375rem; /* 168.75% */
        letter-spacing: 0.04rem;
        text-transform: uppercase;
        @media screen and (max-width: 1070px) {
          font-size: 1.7rem;
          text-align: center;
          margin-bottom: 3rem;
        }
        span.assetance {
          padding: 0.5rem 1rem;
          background: -webkit-linear-gradient(
            35deg,
            var(--primary),
            var(--accent)
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border: 1px solid var(--secondary);
          border-radius: 0.9375rem;
          box-shadow: 1px 5px 9px 0px rgba(0, 86, 224, 0.1);
        }
      }
    }
    & > img {
      max-width: 35rem;
      width: 100%;
    }
  }

  section.signup {
    margin-top: 4rem;
    @media screen and (max-width: 1070px) {
      margin-top: 3rem;
    }
    & > h2 {
      font-family: Lora;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      // margin-bottom: rem;
    }
    & > p.error {
      width: auto;
      height: 1rem;
      margin-bottom: 0.5rem;
      color: red;
      opacity: 0;
      transition-duration: 0.3s;
    }
    & > p.errorClass {
      opacity: 1;
      transition-duration: 0.3s;
    }
    & > form {
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 32rem;
      display: flex;
      flex-flow: row wrap;
      background-color: white;
      padding: 0.5rem;
      border: 1px solid var(--secondary);
      border-radius: 0.625rem;
      box-shadow: 0px 20px 10px -10px rgba(0, 86, 224, 0.1);

      label {
        display: none;
      }
      input {
        max-width: 100%;
        flex-grow: 1;
        border: none;
        padding: 0.5rem;
        font-size: 1.5rem;
        font-weight: 400;
        transition-duration: 1s;
        &::placeholder {
          font-size: 1.5rem;
          font-weight: 600;
        }
        &:focus {
          outline: none;
        }

        &.done {
          animation: slideOut 1s linear 0s 1 forwards;
          transition-duration: 1s;
        }
      }
      button {
        align-items: center;
        flex-grow: 1;
        border: none;
        padding: 1rem 2rem;
        border-radius: 0.625rem;
        background: linear-gradient(100deg, var(--primary), var(--accent));
        color: var(--background-marker);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        transition-duration: 0.3s;
        // @media screen and (max-width: 1070px) {
        //   margin-top: 1rem;
        // }
        &:hover {
          cursor: pointer;
          transform: translateY(-1px);
          scale: 1.02;
          box-shadow: 0px 5px 10px 0px #47b6fe70;
          transition-duration: 0.3s;
        }
        &:active {
          cursor: pointer;
          scale: 0.95;
          transform: translateY(1px);
        }
      }
    }
  }
}

@keyframes slideOut {
  0% {
    max-width: 100%;
    flex-grow: 1;
    padding: 0.5rem;
    opacity: 1;
  }
  100% {
    max-width: 0%;
    padding: 0;
    opacity: 0;
  }
}

// loading icon //////////////////
.loaderContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.lds-ellipsis {
  display: none;
  position: relative;
  transform: translateX(-26px);
}
.lds-ellipsis div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  animation: lds-ellipsis1 0.6s infinite;
  // left: calc(20% + 0px);
}
.lds-ellipsis div:nth-child(2) {
  animation: lds-ellipsis2 0.6s infinite;
  // left: calc(20% + 0px);
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
  // left: calc(20% + 24px);
}
.lds-ellipsis div:nth-child(4) {
  left: 48px;
  animation: lds-ellipsis3 0.6s infinite;
  // left: calc(20% + 48px);
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
